.App {
  text-align: center;
  height: 100vh;
}

.main-nav{
  color: #0765A9;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}

.main-nav:hover{
  color: #EC6428;
  text-decoration: none;
}

.btn{
  display: inline-block;
  background: #ec6428;
  text-transform: uppercase;
  color: #fff;
  padding: 10px 20px;
  font-weight: bold;
  border-radius: 0;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px){
  .navbar-expand-md .navbar-nav {
    width: 370px;
    justify-content: space-around;
  }
  .main-nav-active{
    position: relative;
    color: #EC6428;
  }
  
  .main-nav-active:after{
    content:'';
    background: #EC6428;
    width: 100%;
    height: 2px;
    color: #EC6428;
    display: block;
    position: absolute;
    top: 39px;
  }
}
