textarea{
    resize: none;
}
.mapMargin {
    margin-top: -60px;
    padding: 0px !important;
}
.bg{
    background: url(../../images/background.png) no-repeat;
    background-size: cover;
    padding: 30px 100px;
}

.center{
    display: block;
    text-align: center;
}

.modalControl:focus{
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid rgb(0 0 0 / 29%);
}

.hedader{
    background: grey;
    text-align: left;
    color:#fff;
    padding: 220px 0;
    background: url(../../images/background.png) no-repeat center;
    background-size: cover;
}

.heading{
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    max-width: 635px;
    font-family: 'Montserrat', sans-serif;
}

.subtitle{
    padding: 28px 0 37px 0;
    font-weight: 600;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
}

.span{
    color: #ec6428;
    font-weight: 600;
    font-size: 22px;
}

button:focus {
    outline: none;
    outline: none;
}

.btn{
    display: inline-block;
    background: #ec6428;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

.btn:hover{
    color: #fff;
    text-decoration: none;
    background: rgb(236 87 22);
    cursor: pointer;
}

.services{
    background: #fff;
    text-align: center;
    padding: 90px 0;
}

.lead{
    max-width: 610px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: #666666;
    padding: 0 0 60px 0;
    font-family: 'Montserrat', sans-serif;
}

.title{
    font-size: 30px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.title:after {
    content: ""; 
    background-color: #0765A9;
    height: 2px;
    display: block;
    width: 70px;
    margin: 30px auto;
}

.service{
    display: flex;
    padding: 29px;
    border: 1px solid #cde0ef;
    height: 100%;
}

.text{
    max-width: 250px;
    text-align: left;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    margin: 0 0 0 25px;
    display: flex;
    align-items: center;
}

.card{
    margin: 0 0 20px 0;
    height: 90%;
}

.arrow{
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 50px 0 0 0;
}

.arrow:hover{
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}

.pointer{
    margin: 0 20px 0 0;
}

.modalHeader{
    text-align: center;
    background: linear-gradient(93.33deg, #2461AA 0%, #59BFC7 100%);
    color: #fff;
    border: 0;
}

.modalTitle{
    font-size: 22px;
    line-height: 35px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    padding: 0 55px;
}

.modalBtn:focus{
    outline: none;
    box-shadow: none;
    background: #ec6428;
}

.modalBtn:active{
    outline: none;
    box-shadow: none;
    background: #ec6428;
}

.modalBtn{
    background: #ec6428;
    margin: 50px auto 20px;
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 40px;
    border: none;
}

.modalBody{
    padding: 30px 70px;
}

.modalLabel{
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

.modalControl{
    background: #e7f0f6;
    border: none;
}

.modalBtn:hover{
    color: #fff;
    text-decoration: none;
    background: rgb(236 87 22);
    cursor: pointer;
}

@media (max-width: 765px){
    .hedader{
        padding: 70px 0;
    }    
    .heading{
        font-size: 22px;
        line-height: inherit;
    }
    .subtitle{
        font-size: 16px;
        line-height: normal;
    }
    .btn{
        font-size: 12px;
    }
    .title{
        font-size: 22px;
        line-height: inherit;
    }
    .lead{
        font-size: 14px;
        line-height: inherit;
    }
    .text{
        font-size: 14px;
        line-height: inherit;
    }
    .service{
        padding: 20px 15px;
    }
    .arrow{
        font-size: 14px;
    }
    .modalBody{
        padding: 10px;
    }
    .modalBtn{
        margin: 20px auto;
    }
    .modalTitle{
        font-size: 18px;
        line-height: normal;
        padding: 0;
    }
}




