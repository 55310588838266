.main-nav {
    color: #0765A9;
    text-decoration: none;
    background-color: rgb(0 0 0 / 0%);
}

a:hover{
    color: #ec6429;
}

.brand{
    max-width: 41px;
}

.navbar-expand-md .navbar-nav {
    flex-direction: row;
    width: 40%;
    justify-content: space-around;
}

.btn{
    display: inline-block;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 0;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    position: absolute;
    bottom: 3%;
    right: 10%;
}

.btn:hover{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}

.arrow{
    margin: 0 10px 0 0;
}

.video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000;
}

@media (min-width: 768px){
    .navbar-expand-md .navbar-nav {
      width: 35%;
      justify-content: space-around;
    }
}