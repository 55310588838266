.btn{
    display: inline-block;
    background: #ec6429;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 20px;
    margin: 100px 0 0 0;
    border-radius: 0;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

span{
    color: #fff;
    font-weight: 200;
}

.iframe{
    border: none;
    margin: 60px 0 0 0;
}

.contact{
    text-align: left;
}

.heading{
    color: #fff;
    text-align: left;
    font-size: 30px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}

.btn:hover{
    color: #fff;
    text-decoration: none;
    background: rgb(236 87 22);
    cursor: pointer;
}

.bg{
    background: url(../../images/background.png) no-repeat;
    background-size: cover;
    height: 100vh;
    padding: 60px 0;
}

.content{
    text-align: left;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    justify-content: center;
}

.content a{
    color: #fff;
}

.nopadding{
    padding: 0;
}

.address{
    max-width: 530px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    color: #fff;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.address p{
    padding: 0 0 0 20px;
}

.address:before{
    content: "";
    display: block;
    width: 20px;
    height: 23px;
    background: url(../../images/address.svg) no-repeat;
    background-position: center;
}

.subway{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding: 25px 0 0 0;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.subway p{
    padding: 0 0 0 15px;
}

.subway:before{
    content: "";
    display: block;
    width: 25px;
    height: 23px;
    background-image: url(../../images/metro-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.phone{
    font-weight:600;
    font-size: 20px;
    line-height: 24px;
    padding: 25px 0;
    display: flex;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
}

.phone a{
    padding: 0 0 0 20px;
    text-decoration: none;
}

.phone:before{
    content: "";
    display: block;
    width: 20px;
    height: 23px;
    background-image: url(../../images/phone.svg);
    background-repeat: no-repeat;
    background-position: center;
} 

.mail{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.mail p{
    padding: 0 0 0 20px;
}

.mail:before{
    content: "";
    display: block;
    width: 20px;
    height: 23px;
    background-image: url(../../images/mail.svg);
    background-repeat: no-repeat;
    background-position: center;
} 

.modalHeader{
    text-align: center;
    background: linear-gradient(93.33deg, #2461AA 0%, #59BFC7 100%);
    color: #fff;
}

.modalHeader:nth-child(2){
    background: red;
}

.modalTitle{
    font-size: 22px !important;
    line-height: 35px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    padding: 0;
}
.modalBtn:focus{
    outline: none;
    box-shadow: none;
    background: #ec6428;
}

.modalBtn{
    background: #ec6428 !important;
    margin: 50px auto 20px !important;
    display: block !important;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 17px !important;
    padding: 10px 40px !important;
    border: none !important;
}

.modalBody{
    padding: 30px 70px;
}

.modalLabel{
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

.modalControl{
    background: #e7f0f6;
    border: none;
}

.modalBtn:hover{
    color: #fff;
    text-decoration: none;
    background: rgb(236 87 22);
    cursor: pointer;
}

@media (max-width: 765px){ 
    .btn{
        margin: 20px 0 40px 0;
        font-size: 12px;
    }
    .address{
        max-width: 100%;
    }
    .iframe{
        max-height: 200px;
    }
    .heading{
        font-size: 24px;
    }
    .address{
        font-size: 16px;
        line-height: normal;
    }
    .address p{
        margin: 0;
    }
    .subway{
        font-size: 14px;
        line-height: normal;
    }
    .phone{
        font-size: 16px;
        line-height: normal;
    }
    .phone p{
        margin: 0;
    }
    .mail{
        font-size: 16px;
        line-height: normal;
    }
    .mail p{
        margin: 0 0 25px 0;
    }
    .iframe{
        margin: 0;
    }
    .modalBody{
        padding: 10px;
    }
    .modalBtn{
        margin: 20px auto;
    }
    .modalTitle{
        font-size: 18px;
        line-height: normal;
        padding: 0;
    }
}