a{
    border: 1px solid rgb(0 0 0 / 0%);
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #ec6429;
}

a:active, a:focus { 
    outline: none;
}

.tab-content{
    display: inline-block;
    width: 100%;
}

.mapMargin {
    margin-top: -60px;
    padding: 0px !important;
}
.bg{
    background: url(../../images/background.png) no-repeat;
    background-size: cover;
    padding: 30px 100px;
}

nav{
    justify-content: space-between;
}

nav .nav-link{
    padding: 50px;
}

.tab{
    padding: 50px 0 120px 0;
}

.rent{
    background: url('../../images/background.png') no-repeat;
    background-size: cover;
}

.heading{
    color: #fff;
    padding: 70px 0;
    font-family: 'Montserrat Bold', sans-serif;
    font-weight: bold;
}

.caption{
    position: absolute;
    bottom: 7%;
    left: 7%;
    right: 7%;
    text-align: left;
    color: rgb(255 255 255);
    font-size: 20px;
}

.tabs{
    border: none;
    filter: drop-shadow(0px 5px 45px rgba(0, 0, 0, 0.1));
    margin-top: -30px;
}

.tabs > * {
    color: #ec6429;
    border: none !important;
    background: #fff;
    width: 25%;
    font-weight: bold;
    padding: 17px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    font-size: 14px;
}

.tabs a:global(.active){
    color:#fff !important;
    background: #ec6429 !important;
    border: none;
}

.items ol{
    position: absolute;
    bottom: -60px;
}

.items ol :nth-child(n){
    border-radius: 50%;
    width: 8px;
    height: 8px;
    border: none;
    background: rgb(0 133 193 / 34%); 
    margin: 0% 1%;
}

.items ol li:global(.active){
    background: #ec6429;
}

@media (max-width: 765px){
    .heading{
        font-size: 24px;
    }
    .iLYhoA{
        height: auto;
    }
    .items ol :nth-child(n){
        display: none;
    }
    .tabs > * {
        padding: 20px;
    }
}

